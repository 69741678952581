import cn from "classnames";
import { Swiper, SwiperSlide, Navigation } from "../ui/slider";
import type { Banner } from "../../types";
import { useEffect, useRef } from "react";
import { useIntersection } from "react-use";
import { useHeader } from "../../layouts/headers/header-atom";
import Button from "../ui/button";
import { useModalAction } from "../ui/modal/modal.context";

interface BannerProps {
  banners: Banner[] | undefined;
  layout?: string;
}

const BannerComp: React.FC<BannerProps> = ({ banners, layout }) => {
  const { showHeader, hideHeader } = useHeader();
  const intersectionRef = useRef(null);
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: "60%",
    threshold: 1,
  });
  const { openModal } = useModalAction();

  useEffect(() => {
    if (intersection && intersection.isIntersecting) {
      hideHeader();
      return;
    }
    if (intersection && !intersection.isIntersecting) {
      showHeader();
    }
    console.log(intersection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intersection]);
  return (
    <div
      className={cn("relative hidden lg:block", {
        "!block": layout === "minimal",
      })}
    >
      <div className="-z-1 overflow-hidden" ref={intersectionRef}>
        <div className="relative">
          <Swiper
            id="banner"
            // loop={true}
            modules={[Navigation]}
            resizeObserver={true}
            allowTouchMove={false}
            slidesPerView={1}
          >
            {banners?.map((ban, idx) => (
              <SwiperSlide key={idx}>
                <div
                  className={cn("relative h-screen w-full bg-black", {
                    "max-h-140": layout === "standard",
                    "max-h-[320px] md:max-h-[680px]": layout === "minimal",
                  })}
                >
                  <img
                    className="h-full min-h-140 w-full opacity-30 object-cover object-center"
                    src={ban.image}
                    alt={ban.title ?? ""}
                  />
                  <div
                    className={cn(
                      "absolute inset-0 mt-8 flex w-full flex-col items-center justify-center p-5 text-center md:px-20 lg:space-y-10",
                      {
                        "space-y-5 md:!space-y-8": layout === "minimal",
                      }
                    )}
                  >
                    <h5 className="text-heading text-xl font-semibold">
                      {ban?.description}
                    </h5>

                    <img
                      className="max-w-64 w-64 max-h-64"
                      src={"/Logo-papillon.webp"}
                      alt={"logo"}
                      width="200"
                      height="200"
                    />
                    <h1
                      className={cn(
                        "text-2xl font-bold tracking-tight text-heading lg:text-4xl xl:text-5xl",
                        {
                          "!text-accent": layout === "minimal",
                        }
                      )}
                    >
                      {ban?.title}
                    </h1>
                    {/* <p
                      className={'text-lg font-bold tracking-tight text-accent pt-4'}
                    >
                      {"Opgelet Gesloten op 30 augustus 2024"}
                    </p> */}
                    <div className="flex items-center justify-center space-x-4 rtl:space-x-reverse align-Middle	">
                      <Button
                        className="inline-flex h-12 shrink-0 items-center align-middle justify-center rounded border border-transparent bg-accent px-6 py-6 text-xl font-bold leading-none text-center transition duration-300 ease-in-out hover:bg-accent-hover focus:shadow focus:outline-none focus:ring-1 focus:ring-accent-700"
                        onClick={() => openModal()}
                      >
                        {"Reserveer nu"}
                      </Button>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default BannerComp;
